export const colors = {
  primary: "#bd0320", // shade of red
  secondary: "#780212", // darker shade of red
  accent: "#bdbc03", // yellow green
  background: "#f0f0f0", // very light gray
  subtitle: "#4d4d4d", // dark gray
  black: "#000000", // black
  grey: "#e0e0e0", // gray
  darkGrey: "#afafaf", // dark gray
  orange: "#F6DACF",
  darkOrange: "#C8988F",
  green: "#C4F1EA",
  darkGreen: "#87C6BB",
  white: "#ffffff",
};
